.sidebar {
  // min-width: 250px;
  background-color: #081d34;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // padding: 0px 12px;
  .closeIcon {
    position: absolute;
    right: -15px;
    top: 32px;
    cursor: pointer;
  }
  .openIcon {
    position: absolute;
    right: -15px;
    top: 32px;
    cursor: pointer;
  }

  .logoWrapper {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    // padding-left: 18px;
  }
  .tabButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-top: 10px;
  }
  .navWrapper {
    padding: 0;
    // height: calc(68px * 4);
  }
  .accountWrapper {
    position: absolute;
    bottom: 10px;
  }
  .openApp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: calc(100vh - (106px + (68px * 4)));
    min-height: 160px;
    padding-bottom: 20px;
  }
  .profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    background-color: #0093ff;
    border-radius: 50%;
    font-family: Manrope;
    font-weight: 600;
    color: #fff;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.text {
  font-family: "Manrope Regular", sans-serif;
  font-size: 14px;
  color: white;
  margin-top: 21px;
}

.link {
  font-family: "Manrope ExtraBold", sans-serif;
  color: #0089ed;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  text-decoration: none;
}
.arrow {
  font-size: 20px;
  font-family: "Manrope Light", sans-serif;
}
.workspaceContainer {
  width: 300px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  .title {
    color: #667085;
    font-size: 12px;
    font-weight: 500;
    font-family: "Manrope";
  }
  .flexBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
  }
  .innerFlexBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .subTitle {
    color: #667085;
    font-size: 16px;
    font-weight: 400;
    font-family: "Manrope";
  }
  .avater {
    background: #85b3ff;
    border-radius: 54%;
    width: 26px;
    height: 26px;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
    color: white;
    font-size: 14px;
    font-weight: 400;
    font-family: "Manrope";
  }
  .upgradButton {
    cursor: pointer;
    color: #fff;
    background-color: #0ba5ec;
    font-size: 12px;
    border-radius: 22px;
    padding: 6px 8px !important;
    border: none;
    outline: none;
  }
}
.titleContainer {
  position: relative;
  top: -31px;
  &__title {
    margin-top: 11px;
    font-size: 20px;
    color: #344054;
    font-weight: 700;
    font-family: "Manrope Regular", sans-serif;
  }
  &__description {
    margin-top: 11px;
    color: #344054;
    font-size: 14px;
    line-height: 18px;
    font-family: "Manrope Regular", sans-serif;
  }
}
.contentContainer {
  // max-height: 280px;
  // overflow-y: auto;
  margin-top: 15px;
  width: 600px;
  // overflow: auto;
}

.buttonContainer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  color: #344054;
  font-weight: 400;
  font-family: "Manrope Regular", sans-serif;
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
  }
}
